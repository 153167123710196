import {
  STAKING_HISTORY_KEYS,
  STAKING_HISTORY_SORT_TYPES,
  sortKeys,
} from '../../pages/Staking/stakingConstants';
import * as types from './stakingActionTypes';

const initialState = {
  //dummy staking history data
  stakingHistoryData: null,
  stakingStatisticsData: null,
  stakingHistorySortData: {
    sortType: STAKING_HISTORY_SORT_TYPES.DESCENDING,
    sortKey: STAKING_HISTORY_KEYS.DATE,
  },
  postedStake: null,
  error: {
    type: null,
    data: null,
  },
};

export const stakingReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_STAKING_HISTORY_DATA: {
      const stakingHistoryData = action?.payload.result?.staked_tokens || [];
    
      const sortedStakingHistoryData = [...stakingHistoryData].sort((a, b) => {
        const dateA = new Date(a.udate);
        const dateB = new Date(b.udate);
        return dateB - dateA;
      });
    
      return {
        ...state,
        stakingHistoryData: sortedStakingHistoryData,
        stakingHistorySortData: {
          ...state.stakingHistorySortData,
          sortType: STAKING_HISTORY_SORT_TYPES.DESCENDING,
          sortKey: STAKING_HISTORY_KEYS.DATE,
        },
      };
    }
    case types.GET_STAKING_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_STAKING_HISTORY_ERROR,
          data: action.payload.result,
        },
      };
    case types.GET_STAKING_STATISTICS_DATA:
      return {
        ...state,
        stakingStatisticsData: action?.payload.result ? { ...action?.payload.result } : null,
      };
    case types.GET_STAKING_STATISTICS_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_STAKING_STATISTICS_ERROR,
          data: action.payload.result,
        },
      };
    case types.POST_STAKING_DATA:
      return {
        ...state,
        postedStake: action?.payload.result ? { ...action?.payload.result } : null,
      };
    case types.POST_STAKING_ERROR:
      return {
        ...state,
        error: {
          type: types.POST_STAKING_ERROR,
          data: action.payload.result,
        },
      };

    case types.PUT_STAKING_DATA:
      console.log('PUT_STAKING_DATA')
      console.log(action?.payload)
      return {
        ...state,
        putStake: action?.payload ? { ...action?.payload.result } : null,
      };
    case types.PUT_STAKING_ERROR:
      return {
        ...state,
        error: {
          type: types.PUT_STAKING_ERROR,
          data: action.payload.result,
        },
      };
    case types.SET_STAKING_HISTORY_SORT_DATA:
      return {
        ...state,
        stakingHistorySortData: { ...action.payload.result },
      };
    case types.SORTING_STAKING_HISTORY:
      const selectedKey = sortKeys[state.stakingHistorySortData.sortKey].key;
      const stakingHistoryData = state.stakingHistoryData;
      const sortedStakingHistoryData = stakingHistoryData?.sort((a, b) => {

        const dateA = new Date(a.udate);
        const dateB = new Date(b.udate);
    
        if (state.stakingHistorySortData.sortType === STAKING_HISTORY_SORT_TYPES.DESCENDING) {
          return dateB - dateA; 
        } else {
          return dateA - dateB; 
        }
      });
      
      return {
        ...state,
        stakingHistoryData: sortedStakingHistoryData ? [...sortedStakingHistoryData] : null,
      };
    default:
      return state;
  }
};

import { takeEvery, all, call, put } from 'redux-saga/effects';

import * as types from './stakingActionTypes';
import * as actions from './stakingActions';
import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';
import { errorHandler } from '../../helpers/errorHandler';

function* getStakingHistorySaga(action) {
  try {
    const { id } = action.payload;

    const { data } = yield call(endpoints.getStakingHistory, id);
    yield put(actions.getStakingHistoryDataAction(data));
  } catch (e) {
    yield call(errorHandler, e, action.history, action.href);
    yield put(actions.getStakingHistoryErrorAction(e));
  }
}
function* postStakingSaga(action) {
  try {
    const { data } = yield call(endpoints.postStaking, action.payload);

    yield put(actions.postStakingDataAction(data));
    setTimeout(() => {
      window.location.href = '/staking';
    }, 1000)
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        color: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.postStakingErrorAction(e));
  }
}
function* putStakingSaga(action) {
  try {
    yield put(actions.putStakingDataAction(null));
    const { id } = action.payload;
    const { data } = yield call(endpoints.putStaking, id);
    yield put(actions.putStakingDataAction(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: data.message,
        variant: 'success',
      }),    
    );
    setTimeout(() => {
      window.location.href = '/staking';
    }, 1000)
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        color: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.putStakingErrorAction(e));
  }
}
function* getStakingStatisticsSaga(action) {
  try {
    const { wallet } = action.payload;
    const { data } = yield call(endpoints.getStakingStatistics, wallet);
    yield put(actions.getStakingStatisticsDataAction(data));
  } catch (e) {
    yield call(errorHandler, e, action.history, action.href);
    yield put(actions.getStakingStatisticsErrorAction(e));
  }
}
//Watcher Sagas
function* watchGetStakingHistory() {
  yield takeEvery(types.GET_STAKING_STATISTICS_REQUEST, getStakingStatisticsSaga);
  yield takeEvery(types.GET_STAKING_HISTORY_REQUEST, getStakingHistorySaga);
  yield takeEvery(types.POST_STAKING_REQUEST, postStakingSaga);
  yield takeEvery(types.PUT_STAKING_REQUEST, putStakingSaga);
}

export function* stakingSaga() {
  yield all([watchGetStakingHistory()]);
}

/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { setLoadingAction } from '../../store/loading/loadingActions';
import * as loadingActionTypes from '../../store/loading/loadingActionTypes';
import wallet from '../../helpers/wallet';
import Spinner from 'react-bootstrap/Spinner';
import './MercurXSwapTokenModal.scss';
import { Form, Col } from 'react-bootstrap';
import { abiRequestAction } from '../../store/abi/abiActions';
import { swapTokenModalAction } from '../../store/token/tokenActions';
import { transactionRequest } from '../../store/transaction/transactionActions';
import { mainColors } from '../../helpers/colors';
import { InputGroup } from 'react-bootstrap';
import mercurxMiniIcon from '../../assets/img/logo/mercurx-logo-mini.png';
import mercurxCardDefaultLogo from '../../assets/img/logo/mercurx-logo-mini.png';

function formatNumber(value) {
  return value.toLocaleString('en-US', { minimumFractionDigits: 4 });
}

function isInputValid(value) {
  return parseFloat(value) > 0;
}

function SwapMerxToken({ ...props }) {
  const {
    balance_,
    signerAddress,
    token,
    setLoading,
    isLoading,
    project,
    mercurxProject,
    abiHistoryRequest,
    abiHistory,
    swapTokenModalRequest,
    transactionRequest,
    tierData,
  } = props;

  const [txs, setTxs] = useState([]);
  const [merxTokenInputValue, setMerxTokenInputValue] = useState({
    MerxTokenAmount:
      parseFloat(project?.token.price_in_usd) / parseFloat(mercurxProject?.token.price_in_usd),
    etherValue: 1,
  });
  const [maxMerxTokenAmount, setMaxMerxTokenAmount] = useState("");
  function calculateBalance() {

    const formatBalance = (Math.floor((Number(balance_) / 10000) * 100) / 100).toString().split('.');

    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || "0";
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
    
  }
  const handleAbi = () => {
    abiHistoryRequest();
  };

  const [inputError, setInputError] = useState(false);
  const [maxMerxBalance, setMaxMerxBalance] = useState(0);

  useEffect(() => {
    setMaxMerxBalance(balance_ || 0);
    const calculatedMax = calculateBalance(); 
    setMaxMerxTokenAmount(calculatedMax);
  }, [balance_]);
  const MerxTokenOnChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'etherValue') {
      const merxTokenValue = value;
      merxTokenInputValue.MerxTokenAmount = value;
      merxTokenInputValue.etherValue =
        (parseFloat(mercurxProject?.token.price_in_usd) * parseFloat(value)) /
        parseFloat(project?.token.price_in_usd);
      setMerxTokenInputValue({ ...merxTokenInputValue });
      setInputError(!isInputValid(value));
    } else if (name === 'MerxTokenAmount') {
      const etherValue = value;
      merxTokenInputValue.MerxTokenAmount =
        (parseFloat(value) * parseFloat(project?.token.price_in_usd)) /
        parseFloat(mercurxProject?.token.price_in_usd);
      merxTokenInputValue.etherValue = value;
      setMerxTokenInputValue({ ...merxTokenInputValue });
      setInputError(!isInputValid(value));
    }
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: false,
  });

  const closeModal = () => {
    swapTokenModalRequest(false);
  };

  useEffect(() => {
    if (abiHistory?.[project?.token.symbol + '_abi']) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const mercurx_token = new ethers.Contract(
        project?.token.address,
        abiHistory?.[project?.token.symbol + '_abi'],
        provider,
      );

      try {
        mercurx_token.on('Transfer', (from, to, amount, event) => {
          setTxs((currentTxs) => [
            ...currentTxs,
            {
              txHash: event.transactionHash,
              from,
              to,
              amount: String(amount),
            },
          ]);
        });
      } catch (e) {
      }

      return () => {
        mercurx_token.removeAllListeners();
      };
    }
  }, [abiHistory, project]);

  useEffect(() => {
    handleAbi();
  }, []);

  const swapToken = async (e) => {
    e.preventDefault();
    setTxs([]);
    setLoading({ key: loadingActionTypes.SWAP_TOKEN_LOADING, isLoading: true });
    const data = new FormData(e.target);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    const web3 = new Web3(window.ethereum);
    await wallet.controlAndSwitchOrAddNetwork();
    await window.ethereum.enable();
    const mercurx_token = new web3.eth.Contract(
      abiHistory?.['MERX_abi'],
      mercurxProject?.token?.address,
    );
    const mercurx_swap = new web3.eth.Contract(
      abiHistory?.[project?.token.symbol + '_swap_abi'],
      project?.token.staking_contract.contract_address,
    );
    const etherMiktari = data.get('etherValue');
    const project_id = project?.id;
    const project_name = project?.name;
    const action = 'swap';
    const token_count = merxTokenInputValue.MerxTokenAmount*(10**project?.token.decimals);
    const token_address = project?.token.address;
    const transaction_time = new Date();

    const user_public_address = signerAddress;

    if (project?.token.symbol === 'TRT' && tierData.tier < 1) {
      Swal.fire({
        icon: 'Error',
        iconColor: '#E40039',
        text: `Your Account Needs to be at Least Tier 1 to Swap ${project?.token.symbol} Token`,
        confirmButtonColor: '#E40039',
      }).then(closeModal);
      setLoading({ key: loadingActionTypes.SWAP_TOKEN_LOADING, isLoading: false });
      setLoading({ key: loadingActionTypes.BUY_MERXTOKEN_LOADING, isLoading: false });
    } else {
      try {
        let val = parseFloat(merxTokenInputValue.etherValue).toFixed(4);
        val = parseFloat(val.toString().replace('.', ''), 10);
        const approve_transaction = await mercurx_token.methods
          .approve(project?.token.staking_contract.contract_address, val)
          .send({
            from: signerAddress,
            to: project?.token.staking_contract.contract_address,
            data: web3.eth.abi.encodeFunctionSignature('whitdrawETH()'),
          });
        setLoading({ key: loadingActionTypes.SWAP_TOKEN_LOADING, isLoading: false });
        setLoading({ key: loadingActionTypes.BUY_MERXTOKEN_LOADING, isLoading: true });
        let parseIntVal = parseFloat(etherMiktari).toFixed(4).toString();
        parseIntVal = parseFloat(parseIntVal.replace('.', ''), 10);
        const transaction = await mercurx_swap.methods
          .swap(parseIntVal, val)
          .send({
            from: signerAddress,
            to: project?.token.staking_contract.contract_address,
            data: web3.eth.abi.encodeFunctionSignature('whitdrawETH()'),
          });
        wallet.getMyBalance(mercurxProject?.token?.address);
        const transaction_status = transaction.status;
        const transaction_hash = transaction.transactionHash;
        const payload2 = {
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
          action,
        };

        transactionRequest(payload2);

        Swal.fire({
          icon: 'success',
          iconColor: mainColors.primary,
          text: 'Transaction succeed',
          confirmButtonColor: mainColors.primary,
          html: `<a href=https://testnet.bscscan.com/tx/${transaction.transactionHash} target='_blank'> Check Detail Transaction !</a>`,
        }).then(closeModal);
        setLoading({ key: loadingActionTypes.SWAP_TOKEN_LOADING, isLoading: false });
        setLoading({ key: loadingActionTypes.BUY_MERXTOKEN_LOADING, isLoading: false });
      } catch (err) {

        if (err?.receipt?.transactionHash) {
          Swal.fire({
            icon: 'error',
            iconColor: '#E40039',
            title: 'Transaction is Failed',
            confirmButtonColor: '#E40039',
            html: `<a href=https://testnet.bscscan.com/tx/${err.receipt.transactionHash} target='_blank'> Check Detail Transaction !</a>`,
          }).then(closeModal);
          setLoading({ key: loadingActionTypes.SWAP_TOKEN_LOADING, isLoading: false });
          setLoading({ key: loadingActionTypes.BUY_MERXTOKEN_LOADING, isLoading: false });
          const transaction_status = false;
          const transaction_hash = err.receipt.transactionHash;
          const payload2 = {
            project_id,
            project_name,
            token_count,
            user_public_address,
            token_address,
            transaction_hash,
            transaction_time,
            transaction_status,
            action,
          };
          transactionRequest(payload2);
        } else {
          Swal.fire({
            icon: 'warning',
            iconColor: '#E40039',
            confirmButtonColor: '#E40039',
            text: err.message,
          }).then(closeModal);
          setLoading({ key: loadingActionTypes.SWAP_TOKEN_LOADING, isLoading: false });
          setLoading({ key: loadingActionTypes.BUY_MERXTOKEN_LOADING, isLoading: false });
        }
      }
    }
  };

  return (
    <>
      <form className="m-0" onSubmit={swapToken}>
        <div
          className="credit-card w-full lg:w-3/4 sm:w-auto 
          shadow-lg mx-auto rounded-xl"
        >
          <main className="px-4">
            <h1 className="d-flex justify-content-center text-fs-head-md">
              {project?.token.symbol}/MERX
            </h1>
            <div className="mx-3">
              <div className="my-3">
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="MerxTokenAmount"
                    id="MerxTokenAmount"
                    className="input input-bordered text-fs-body-md text-t-body-color bg-light"
                    placeholder="MerxTokenAmount"
                    value={formatNumber(merxTokenInputValue.etherValue)}
                    onChange={MerxTokenOnChangeHandler}
                    min="0"
                    step="0.0001"
                    // max="1000"
                    max={parseFloat(maxMerxTokenAmount.replace(',', '.'))}
                    disabled={
                      isLoading?.[loadingActionTypes.SWAP_TOKEN_LOADING] ||
                      isLoading?.[loadingActionTypes.BUY_MERXTOKEN_LOADING]
                    }
                  />
                  <InputGroup.Text id="basic-addon1" style={{ width: '95px' }}>
                    <Col className="d-flex justify-content-between align-items-center">
                      MERX
                      <img
                        className="mercurx-icon ms-1 me-1"
                        alt="mercurx-mini-icon"
                        src={mercurxMiniIcon}
                        style={{ maxWidth: '20px', maxHeight: '20px' }}
                      />
                    </Col>
                  </InputGroup.Text>
                </InputGroup>
                {inputError && (
                  <Form.Label className="text-danger text-fs-body-sm mercurx-error-label">
                    * The input value must be greater than 0.
                  </Form.Label>
                )}
              </div>
              <div className="my-3">
                <InputGroup>
                  <Form.Control
                    type="number"
                    name="etherValue"
                    id="etherValue"
                    className="input input-bordered text-fs-body-md text-t-body-color bg-light"
                    placeholder="Ether Value"
                    min="0"
                    step="0.0001"
                    max="1000"
                    value={formatNumber(merxTokenInputValue.MerxTokenAmount)}
                    onChange={MerxTokenOnChangeHandler}
                    disabled={
                      isLoading?.[loadingActionTypes.SWAP_TOKEN_LOADING] ||
                      isLoading?.[loadingActionTypes.BUY_MERXTOKEN_LOADING]
                    }
                  />
                  <InputGroup.Text id="basic-addon2" style={{ width: '95px' }}>
                    <Col className="d-flex justify-content-between align-items-center">
                      {project?.token.symbol}
                      <img
                        alt="project-icon"
                        src={process.env.REACT_APP_API_URL + '/projects/' + project?.id + '/logo'}
                        className="project-icon"
                        onError={logoOnErrorHandler}
                        style={{ maxWidth: '20px', maxHeight: '20px' }}
                      />
                    </Col>
                  </InputGroup.Text>
                </InputGroup>
                {inputError && (
                  <Form.Label className="text-danger text-fs-body-sm mercurx-error-label">
                    * The input value must be greater than 0.
                  </Form.Label>
                )}
              </div>
            </div>
          </main>
          <footer className="d-flex justify-content-end p-3 mx-4">
            <button
              type="submit"
              className="btn btn-primary d-flex justify-content-center"
              disabled={
                isLoading?.[loadingActionTypes.SWAP_TOKEN_LOADING] ||
                isLoading?.[loadingActionTypes.BUY_MERXTOKEN_LOADING] ||
                inputError
              }
            >
              {isLoading?.[loadingActionTypes.BUY_MERXTOKEN_LOADING] ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                  </Spinner>
                  <span className="ml-2">Pending Transaction...</span>
                </div>
              ) : isLoading?.[loadingActionTypes.SWAP_TOKEN_LOADING] ? (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                  </Spinner>
                  <span className="ml-2">Approving Transaction...</span>
                </div>
              ) : (
                'Swap'
              )}
            </button>
          </footer>
        </div>
      </form>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    provider2: state.walletReducer.provider2,
    project: state.projectReducer.project,
    mercurxProject: state.projectReducer.mercurxProject,
    signer: state.walletReducer.signer,
    signerAddress: state.walletReducer.signerAddress,
    web3: state.walletReducer.web3,
    erc20_: state.walletReducer.erc20_,
    balance_: state.walletReducer.balance_,
    contractAddress: state.walletReducer.contractAddress,
    token: state.tokenReducer.token,
    isLoading: state.loadingReducer.isLoading,
    abiHistory: state.abiReducer.abiHistory,
    tierData: state.tierReducer.tierData,
  };
};

const logoOnErrorHandler = (event) => {
  event.currentTarget.src = mercurxCardDefaultLogo;
  event.currentTarget.className = 'project-icon';
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => {
      dispatch(setLoadingAction(payload));
    },
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    swapTokenModalRequest: (payload) => {
      dispatch(swapTokenModalAction(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SwapMerxToken);

import { useEffect, useState } from 'react';
import { Pagination, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import './MercurXStakingHistoryTable.scss';
import Button from 'react-bootstrap/Button';
// eslint-disable-next-line max-len
import wallet from '../../helpers/wallet';
import { putStakingRequestAction } from '../../store/staking/stakingActions';
import Swal from 'sweetalert2';
import Web3 from 'web3';
import * as loadingActionTypes from '../../store/loading/loadingActionTypes';
import { transactionRequest } from '../../store/transaction/transactionActions';
import { ethers } from 'ethers';
import { abiRequestAction } from '../../store/abi/abiActions';
import { setLoadingAction, setLoadingFullAction } from '../../store/loading/loadingActions';
import { mainColors } from '../../helpers/colors';
import { getProjects } from '../../store/project/projectActions';

function MercurXStakingHistoryTable(props) {
  const {
    stakingHistoryData,
    balance_,
    accounts,
    setLoading,
    setLoadingFull,
    abiHistory,
    abiHistoryRequest,
    putStakingRequest,
    isLoading,
    transactionRequest,
    projects,
    getProjects,
  } = props;
  const [txs, setTxs] = useState([]);
  const handleAbi = () => {
    abiHistoryRequest();
  };
  useEffect(() => {
    getProjects();
  }, []);

  const dateObj = new Date();
  useEffect(() => {
    setCurrentPage(1);
    wallet.getMyBalance(projects?.[1].token.address);
  }, [accounts]);
  const maxRowCountPerPage = 5;
  const maxShowingPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedData, setRenderedData] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] = useState(maxShowingPage);
  function calculateFormat(x) {

    const formatBalance = (Math.floor((Number(x) / 10000) * 100) / 100).toString().split('.');

    const firstValueFormatBalance = Number(formatBalance[0]).toLocaleString('tr-TR');
    const secondValueFormatBalance = formatBalance[1] || "0";
    return firstValueFormatBalance + ',' + secondValueFormatBalance;
  }
  useEffect(() => {
    if (stakingHistoryData) {
      const tempPages = [];
      for (
        let index = 0;
        index < Math.ceil(stakingHistoryData.length / maxRowCountPerPage);
        index++
      ) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [stakingHistoryData, maxRowCountPerPage, maxShowingPage, lastPageOfPagination]);
  
  useEffect(() => {
    if (stakingHistoryData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = stakingHistoryData?.slice(firstIndex, lastIndex);
      setRenderedData([...tempRendered]);
    }
  }, [stakingHistoryData, currentPage, maxRowCountPerPage]);  

  function scrollToStakingHistoryContainer() {
    const stakingHistory = document.getElementById('staking-history-container');
    if (stakingHistory) {
      stakingHistory.scrollIntoView({ behavior: 'smooth' });
    }
  }
  useEffect(() => {
    console.log("stakingHistoryData updated:", stakingHistoryData);
  }, [stakingHistoryData]);
  
  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToStakingHistoryContainer();
  };

  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToStakingHistoryContainer();
  };

  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToStakingHistoryContainer();
    }
  };

  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToStakingHistoryContainer();
    }
  };

  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToStakingHistoryContainer();
  };
  const formatDate = (originalDate) => {

    const dateObj = new Date(originalDate);
    const newdateObj = new Date(
      dateObj.getUTCFullYear(),
      dateObj.getUTCMonth(),
      dateObj.getUTCDate(),
      dateObj.getUTCHours(),
      dateObj.getUTCMinutes(),
      dateObj.getUTCSeconds(),
    );
    return newdateObj;
  };
  const formatDateClaim = (originalDate) => {

    const dateObj = new Date(originalDate);
    const newdateObj = new Date(
      dateObj.getUTCFullYear(),
      dateObj.getUTCMonth(),
      dateObj.getUTCDate(),
      dateObj.getUTCHours() +3,
      dateObj.getUTCMinutes(),
      dateObj.getUTCSeconds(),
    );
    return newdateObj;
  };
  useEffect(() => {
    handleAbi();
  }, []);
  
  function putStaking(uid) {
    const payload = {
      id: uid,
    };
    putStakingRequest(payload);
  }
  const claimToken = async ( e,selectedItem) => {
    setTxs([]);
    setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
    setLoading({ key: loadingActionTypes.CLAIM_TOKEN_LOADING, isLoading: true });
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    const web3 = new Web3(window.ethereum);
    await wallet.controlAndSwitchOrAddNetwork();
    await window.ethereum.enable();
    const mercurx_token = new web3.eth.Contract(
      abiHistory?.['MERX_abi'],
      projects?.[1].token.address,
    );
    const mercurx_stake = new web3.eth.Contract(
      abiHistory?.['MERX_staking_abi'],
      projects?.[1].token.staking_contract.contract_address,
    );
    const project_id = 1;
    const project_name = projects?.[1].token.name;
    const token_count = selectedItem.value * (10**projects?.[1].token.decimals);
    const action = 'unstake';
    const token_address = projects?.[1].token.address;
    const transaction_time = new Date();
    const user_public_address = signerAddress;
    try {
      const transaction = await mercurx_stake.methods
      .unstake(
        web3.utils.toWei(String(Number(selectedItem.value) * (10**projects?.[1].token.decimals) ), 
    'wei')) 
      .send({
        from: signerAddress,
        to:  projects?.[1].token.staking_contract.contract_address,
        data: [web3.eth.abi.encodeFunctionSignature('whitdrawETH()'), 0],
      });
      // await mercurx_stake.methods.unstake(e).send({
      //   from: signerAddress,
      //   to: projects?.[1].token.staking_contract.contract_address,
      //   data: [web3.eth.abi.encodeFunctionSignature('whitdrawETH()'), 0],
      // });
      wallet.getMyBalance(projects?.[1].token.address);
      const transaction_status = transaction.status;
      const transaction_hash = transaction.transactionHash;
      const payload2 = {
        project_id,
        project_name,
        token_count,
        user_public_address,
        token_address,
        transaction_hash,
        transaction_time,
        transaction_status,
        action,
      };
      transactionRequest(payload2);
      putStaking(e);
      Swal.fire({
        icon: 'success',
        iconColor: mainColors.primary,
        text: 'Transaction succeed',
        confirmButtonColor: mainColors.primary,
        html:
          '<a href=https://testnet.bscscan.com/tx/' +
          transaction.transactionHash +
          " target='_blank'> Check Detail Transaction !</a>",
      });
      setLoading({ key: loadingActionTypes.CLAIM_TOKEN_LOADING, isLoading: false });
      setLoadingFull({ isLoading: false });

    } catch (err) {
      if (err?.receipt?.transactionHash) {
        Swal.fire({
          icon: 'error',
          iconColor: '#E40039',
          title: 'Transaction is Failed',
          confirmButtonColor: '#E40039',
          // eslint-disable-next-line max-len, no-template-curly-in-string
          html:
            '<a href=https://testnet.bscscan.com/tx/' +
            err.receipt.transactionHash +
            " target='_blank'> Check Detail Transaction !</a>",
        });
        const transaction_status = false;
        const transaction_hash = err.receipt.transactionHash;
        const payload2 = {
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
          action,
        };
        transactionRequest(payload2);
        setLoading({ key: loadingActionTypes.CLAIM_TOKEN_LOADING, isLoading: false });
        setLoadingFull({ isLoading: false });
      } else {
        Swal.fire({
          icon: 'warning',
          iconColor: '#E40039',
          confirmButtonColor: '#E40039',
          text: err.message,
        });
        setLoading({ key: loadingActionTypes.CLAIM_TOKEN_LOADING, isLoading: false });
        setLoadingFull({ isLoading: false });
      }
    }
  };
  return (
    <>
      <Table className="staking-history-table" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Token</th>
            <th>Value</th>
            <th>Stake Date</th>
            <th>Unstake Date</th>
            <th>Status</th>

          </tr>
        </thead>
        {renderedData?.length ? (
          <tbody>
            {renderedData.map((item, index) => {
              const dateObj = formatDate(item.finish_lock_time);
              const date1 = new Date();
              if (currentPage * index < currentPage * maxRowCountPerPage) {
                return (
                  <tr key={index} className="text-t-head-color">
                    <td>{index + 1}</td>
                    <td>{item.symbol}</td>
                    {/* <td>{calculateFormat(item.value)}</td> */}
                    <td>{(item.value)}</td>
                    <td>{formatDate(item.start_lock_time).
                    toLocaleString()+' GMT+3'}</td>
                    <td>{!item.claim_status?
                    (formatDateClaim(item.udate).
                    toLocaleString()+' GMT+3'):("")}</td> 
                    <td>
                      {item.claim_status ? ((
                          <Button 
                            onClick={() => claimToken(item.id, item)}
                          variant="primary" disabled={false}>
                            Unstake
                          </Button>
                        )
                      ) : (
                        <Button variant="primary" disabled={true}>
                          Done
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        ) : null}
      </Table>

      {!renderedData?.length && (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No staking history found.
        </div>
      )}

      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => {
            return (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    stakingHistoryData: state.stakingReducer.stakingHistoryData,
    balance_: state.walletReducer.balance_,
    abiHistory: state.abiReducer.abiHistory,
    isLoading: state.loadingReducer.isLoading,
    projects: state.projectReducer.projects,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    putStakingRequest: (payload) => {
      dispatch(putStakingRequestAction(payload));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    setLoading: (payload) => {
      dispatch(setLoadingAction(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
    getProjects: (payload) => {
      dispatch(getProjects(payload));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MercurXStakingHistoryTable);

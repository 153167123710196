/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import Web3 from 'web3';
import { ethers } from 'ethers';
import { setLoadingAction } from '../../store/loading/loadingActions';
import * as loadingActionTypes from '../../store/loading/loadingActionTypes';
import wallet from '../../helpers/wallet';
import Spinner from 'react-bootstrap/Spinner';
import './MercurXBuyTokenModal.scss';
import { Col, Form } from 'react-bootstrap';
import { abiRequestAction } from '../../store/abi/abiActions';
import { buyTokenModalAction, getBNB } from '../../store/token/tokenActions';
import { transactionRequest } from '../../store/transaction/transactionActions';
import { mainColors } from '../../helpers/colors';
import { InputGroup } from 'react-bootstrap';
import { SiBinance } from 'react-icons/si';
import mercurxCardDefaultLogo from '../../assets/img/logo/mercurx-logo-mini.png';
import { checkUserTokenRequestAction } from '../../store/account/userActions';

function formatNumber(value) {
  return value.toLocaleString('en-US', { minimumFractionDigits: 9 });
}

function isInputValidTokenAmount(value) {
  return parseFloat(value) >= 1;
}
function isInputValid(value) {
  return parseFloat(value) > 0;
}

function BuyMerxToken({ ...props }) {
  const {
    balance_,
    signerAddress,
    token,
    setLoading,
    isLoading,
    project,
    abiHistoryRequest,
    getBNBRequest,
    abiHistory,
    buyTokenModalRequest,
    transactionRequest,
    tierData,
    bnbData,
    item,
    checkUserTokenWeb3,
  } = props;
  const [txs, setTxs] = useState([]);
  const [merxTokenInputValue, setMerxTokenInputValue] = useState(
    {
    MerxTokenAmount: 1,
    etherValue: parseFloat(bnbData?.price)/parseFloat(project?.token.price_in_usd),
  });

  useEffect(() => {
    merxTokenInputValue.MerxTokenAmount = 1;
    merxTokenInputValue.etherValue =  parseFloat(project?.token.price_in_usd)/parseFloat(bnbData?.price);
    setMerxTokenInputValue({ ...merxTokenInputValue });
  }, [bnbData]);
  const [inputError, setInputError] = useState(false);

  const MerxTokenOnChangeHandler = (event) => {
    const { name, value } = event.target;
    if (name === 'etherValue') {
      merxTokenInputValue.MerxTokenAmount = (parseFloat(bnbData?.price) / parseFloat(project?.token.price_in_usd)) * parseFloat(value) ;
      merxTokenInputValue.etherValue = value;
      setMerxTokenInputValue({ ...merxTokenInputValue });
      setInputError(!isInputValid(value));
    } else if (name === 'MerxTokenAmount') {
      merxTokenInputValue.MerxTokenAmount = value
      merxTokenInputValue.etherValue = (parseFloat(value) * parseFloat(project?.token.price_in_usd))/parseFloat(bnbData?.price);
      setMerxTokenInputValue({ ...merxTokenInputValue });
      setInputError(!isInputValidTokenAmount(value));
    }
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger',
    },
    buttonsStyling: false,
  });

  const closeModal = () => {
    buyTokenModalRequest(false);
  };
  const logoOnErrorHandler = (event) => {
    event.currentTarget.src = mercurxCardDefaultLogo;
    event.currentTarget.className = 'project-icon';
  };

  const handleAbi = () => {
    getBNBRequest();
    abiHistoryRequest();
  };

  useEffect(() => {

    if (abiHistory?.[project?.token.symbol.toUpperCase()  + '_abi']) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const mercurx_token = new ethers.Contract(
        project?.token.address,
        abiHistory?.[project?.token.symbol.toUpperCase() + '_abi'],
        provider,
      );

      try {
        mercurx_token.on('Transfer', (from, to, amount, event) => {
          setTxs((currentTxs) => [
            ...currentTxs,
            {
              txHash: event?.transactionHash,
              from,
              to,
              amount: String(amount),
            },
          ]);
        });
      } catch (e) {
      }

      return () => {
        mercurx_token.removeAllListeners();
      };
    }
  }, [abiHistory, project]);

  useEffect(() => {
    handleAbi();
  }, []);
  useEffect(() => {

  }, []);
  let val = 0.0;


  const buyToken = async (e) => {
    e.preventDefault();
    const userToken = JSON.parse(localStorage.getItem('user'))?.token;
    if (userToken) {
        await checkUserTokenWeb3(); 
    } else {
        return; 
    }
    setTxs([]);
    setLoading({ key: loadingActionTypes.BUY_MERXTOKEN_LOADING, isLoading: true });
    const data = new FormData(e.target);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = await provider.getSigner();
    const signerAddress = await signer.getAddress();
    const web3 = new Web3(window.ethereum);
    await wallet.controlAndSwitchOrAddNetwork();
    await window.ethereum.enable();
    const mercurx_presale = new web3.eth.Contract(
      abiHistory?.[project?.token.symbol.toUpperCase() + '_presale_abi'],
      project?.token.presale_contract.contract_address,
    );
    const etherMiktari = data.get('etherValue');
    const project_id = project?.id;
    const project_name = project?.name;
    const action = 'buy';
    const token_count = merxTokenInputValue.MerxTokenAmount*(10**project?.token.decimals);
    const token_address = project?.token.address;
    const transaction_time = new Date();
    const user_public_address = signerAddress;
    if (project?.token.symbol === 'TRT' && tierData.tier < 1) {
      Swal.fire({
        icon: 'Error',
        iconColor: '#E40039',
        text:
          'Your Account Needs to be at Least Tier 1 to Swap ' + project?.token.symbol + ' Token',
        confirmButtonColor: '#E40039',
      }).then(closeModal);
      setLoading({ key: loadingActionTypes.SWAP_TOKEN_LOADING, isLoading: false });
      setLoading({ key: loadingActionTypes.BUY_MERXTOKEN_LOADING, isLoading: false });
    } else {
      try {
        val = parseFloat(bnbData?.price)* parseFloat(etherMiktari) / parseFloat(project?.token.price_in_usd)
        val = val.toFixed(project?.token.decimals).toString();
        val = parseFloat(val.replace('.', ''), 10);
        let bnb = parseFloat(etherMiktari).toFixed(18);
        bnb = parseFloat(bnb.toString().replace('.', ''), 10);
        const transaction = await mercurx_presale.methods.buyTokens(val).send({
          from: signerAddress,
          to: project?.token.presale_contract.contract_address,
          data: web3.eth.abi.encodeFunctionSignature('whitdrawETH()'),
          value: bnb,
        });
        wallet.getMyBalance(project?.token.address);
        setLoading({ key: loadingActionTypes.BUY_MERXTOKEN_LOADING, isLoading: false });
        const transaction_status = transaction.status;
        const transaction_hash = transaction?.transactionHash;
        const payload2 = {
          project_id,
          project_name,
          token_count,
          user_public_address,
          token_address,
          transaction_hash,
          transaction_time,
          transaction_status,
          action,
        };
        transactionRequest(payload2);

        Swal.fire({
          icon: 'success',
          iconColor: mainColors.primary,
          text: 'Transaction succeed',
          confirmButtonColor: mainColors.primary,
          html:
            '<a href=https://testnet.bscscan.com/tx/' +
            transaction?.transactionHash +
            " target='_blank'> Check Detail Transaction !</a>",
        }).then(closeModal);
      } catch (err) {
        if (err) {
          Swal.fire({
            icon: 'error',
            iconColor: '#E40039',
            title: 'Transaction is Failed',
            confirmButtonColor: '#E40039',
            html:
              '<a href=https://testnet.bscscan.com/tx/' +
              err.receipt?.transactionHash +
              " target='_blank'> Check Detail Transaction !</a>",
          }).then(closeModal);
          setLoading({ key: loadingActionTypes.BUY_MERXTOKEN_LOADING, isLoading: false });
          const transaction_status = false;
          const transaction_hash = err.receipt?.transactionHash;
          const payload2 = {
            project_id,
            project_name,
            token_count,
            user_public_address,
            token_address,
            transaction_hash,
            transaction_time,
            transaction_status,
            action,
          };
          transactionRequest(payload2);
        } else {
          Swal.fire({
            icon: 'warning',
            iconColor: '#E40039',
            confirmButtonColor: '#E40039',
            text: err.message,
          }).then(closeModal);
          setLoading({ key: loadingActionTypes.BUY_MERXTOKEN_LOADING, isLoading: false });
        }
      }
    }
  };


  return (
    <>
      <form className="m-0" onSubmit={buyToken}>
        <div
          className="credit-card w-full lg:w-3/4 sm:w-auto 
          shadow-lg mx-auto rounded-xl"
        >
          <main className="px-4">
            <h1 className="d-flex justify-content-center text-fs-head-md">
              {project?.token.symbol.toUpperCase()}/BNB
            </h1>
            <div className="mx-3">
              <div className="my-3">
                <div className="input-group">
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="etherValue"
                      id="etherValue"
                      className="input input-bordered text-fs-body-md text-t-body-color bg-light"
                      placeholder="BNB Value"
                      min="0"
                      step="any"
                      value={(merxTokenInputValue.etherValue)}
                      onChange={MerxTokenOnChangeHandler}
                      disabled={isLoading?.[loadingActionTypes.BUY_MERXTOKEN_LOADING]}
                    />
                    <InputGroup.Text id="basic-addon1" style={{ width: '95px' }}>
                      <Col className="d-flex justify-content-between align-items-center">
                        <span>BNB</span>
                        <SiBinance color="black" />
                      </Col>
                    </InputGroup.Text>
                  </InputGroup>
                  {inputError && (
                    <Form.Label className="text-danger text-fs-body-sm mercurx-error-label">
                      * The input value must be greater than 0.
                    </Form.Label>
                  )}
                </div>
              </div>
              <div className="my-3">
                <div className="input-group">
                  <InputGroup>
                    <Form.Control
                      type="number"
                      name="MerxTokenAmount"
                      id="MerxTokenAmount"
                      className="input input-bordered text-fs-body-md text-t-body-color bg-light"
                      placeholder="MerxTokenAmount"
                      value={(merxTokenInputValue.MerxTokenAmount)}
                      onChange={MerxTokenOnChangeHandler}
                      min="1"
                      step="any"
                      disabled={isLoading?.[loadingActionTypes.BUY_MERXTOKEN_LOADING]}
                    />
                    <InputGroup.Text id="basic-addon2" style={{ width: '95px' }}>
                      <Col className="d-flex justify-content-between align-items-center">
                        {project?.token.symbol.toUpperCase()}{' '}
                        <img
                          alt="token-icon"
                          src={process.env.REACT_APP_API_URL + '/projects/' + project?.id + '/logo'}
                          className="token-icon"
                          onError={logoOnErrorHandler}
                          style={{ maxWidth: '20px', maxHeight: '20px' }}
                        />
                      </Col>
                    </InputGroup.Text>
                  </InputGroup>
                  {inputError && (
                    <Form.Label className="text-danger text-fs-body-sm mercurx-error-label">
                      * The input value must be greater than 1.
                    </Form.Label>
                  )}
                  <div className="input-group-append"></div>
                </div>
              </div>
            </div>
          </main>

          <footer className="d-flex justify-content-end p-3 mx-4">
            <button
              type="submit"
              className="btn btn-primary d-flex justify-content-center"
              disabled={isLoading?.[loadingActionTypes.BUY_MERXTOKEN_LOADING] || inputError}
            >
              {' '}
              {!isLoading?.[loadingActionTypes.BUY_MERXTOKEN_LOADING] ? (
                'Buy'
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden"></span>
                  </Spinner>
                  <span className="ml-2">Pending Transaction...</span>
                </div>
              )}
            </button>
          </footer>
        </div>
      </form>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    provider2: state.walletReducer.provider2,
    project: state.projectReducer.project,
    signer: state.walletReducer.signer,
    signerAddress: state.walletReducer.signerAddress,
    web3: state.walletReducer.web3,
    erc20_: state.walletReducer.erc20_,
    balance_: state.walletReducer.balance_,
    contractAddress: state.walletReducer.contractAddress,
    token: state.tokenReducer.token,
    isLoading: state.loadingReducer.isLoading,
    abiHistory: state.abiReducer.abiHistory,
    tierData: state.tierReducer.tierData,
    bnbData: state.tokenReducer.bnb,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLoading: (payload) => {
      dispatch(setLoadingAction(payload));
    },
    abiHistoryRequest: (payload) => {
      dispatch(abiRequestAction(payload));
    },
    buyTokenModalRequest: (payload) => {
      dispatch(buyTokenModalAction(payload));
    },
    getBNBRequest: (payload) => {
      dispatch(getBNB(payload));
    },
    transactionRequest: (creds) => {
      dispatch(transactionRequest(creds));
    },
    checkUserTokenWeb3: (payload) => {
      dispatch(checkUserTokenRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyMerxToken);

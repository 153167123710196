import { takeEvery, call, put, all } from 'redux-saga/effects';

import * as types from './projectActionTypes';
import * as actions from './projectActions';
import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';
import { errorHandler } from '../../helpers/errorHandler';

function* getProjectsSaga(action) {
  try {
    const { data } = yield call(endpoints.getProjects);
    yield put(actions.getProjectsData(data));
  } catch (e) {
    yield call(errorHandler, e, action.history, action.href);
    yield put(actions.getProjectsError(e));
  }
}

function* getProjectByIDSaga(action) {
  try {
    yield put(actions.getProjectByIDData(null));
    const { id } = action.payload;
    const { data } = yield call(endpoints.getProjectByID, id);
    yield put(actions.getProjectByIDData(data));
  } catch (e) {
    yield call(errorHandler, e, action.history, action.href);
    yield put(actions.getProjectByIDError(e));
  }
}

function* addProject(action) {
  try {
    const { data } = yield call(endpoints.addProject, action.data);

    yield put(actions.projectAddedAction(data));
    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: data.message,
        variant: 'success',
      }),
    );
  } catch (e) {
    yield call(errorHandler, e, action.history, action.href);

  }
}

function* getMercurxProjectSaga(action) {
  try {
    yield put(actions.getMercurxProjectDataAction(null));
    const { data } = yield call(endpoints.getProjectByID, 1);
    yield put(actions.getMercurxProjectDataAction(data));
  } catch (e) {
    yield call(errorHandler, e, action.history, action.href);
    yield put(actions.getMercurxProjectErrorAction(e));
  }
}

function* watchGetProjects() {
  yield takeEvery(types.GET_PROJECTS_REQUEST, getProjectsSaga);
}

function* watchGetProjectByID() {
  yield takeEvery(types.GET_PROJECT_REQUEST, getProjectByIDSaga);
}

function* watchGetMercurxProject() {
  yield takeEvery(types.GET_MERCURX_PROJECT_REQUEST, getMercurxProjectSaga);
}

function* watchAddProject() {
  yield takeEvery(types.ADD_PROJECT, addProject);
}

export function* projectSaga() {
  yield all([
    watchAddProject(),
    watchGetProjectByID(),
    watchGetProjects(),
    watchGetMercurxProject(),
  ]);
}
